
import Vue from 'vue'
import { isNotNested, parseData, parseForm } from '../../mixins/formData'

export default Vue.extend({
  name: 'ProjektEingereicht',

  data: () => {
    const formData: any = {}
    const project: any = {}
    return {
      dots: '.',
      errors: [],
      formData,
      formObj: {},
      interv: 0,
      isLoading: true,
      project,
    };
  },

  computed: {
    baseUrl () {
      return this.$store.state.baseUrl
    },
    awardConf () {
      return this.$store.state.currentAwardConf
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part || this.$store.state.currentAwardConf.forms.user_submit.parts[0].key
    },
    formParts () {
      if (!this.$store.state.currentAwardConf) return null
      return this.$store.state.currentAwardConf.forms.user_submit.parts
    },
    id () {
      return this.$route.params.id;
    },
    pdfDocuments(): any {
      const { awardType, year } = this.currentAward
      if (this.$store.state.appInfo?.awards?.pdfDocuments[awardType]) {
        return this.$store.state.appInfo?.awards?.pdfDocuments[awardType][year]
      } else {
        return null
      }
    },
    projectCategories () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.meta.projectCategories : null
    },
  },

  methods: {
    animateDots () {
      if (this.dots.length <= 4) {
        this.dots += '.';
      } else {
        this.dots = '.';
      }
    },

    initProject() {
      this.$store
        .dispatch('getProjectItem', this.$route.params.id)
        .then((project) => {
          this.project = project
          this.isLoading = false
        });
    },
    
    getPdfPath(p: any, type: string) {
      const { awardType, year } = this.currentAward
      if (!this.pdfDocuments) return ''
      let url = this.baseUrl.slice(0, -1) + this.pdfDocuments[type].url
      url = url.replace('-AWARDTYPE-', awardType)
      url = url.replace('-YEAR-', year)
      url = url.replace('-ID-', p.id)
      return url
    },

    async saveProject() {
      this.isLoading = true
      this.interv = window.setInterval(this.animateDots, 200);
      const { awardType, year } = this.currentAward
      const formName = 'user_submit'
      let formData = this.parseForm(this.formParts, this.formData)[this.currentPart]
      if (formData.bedingungen === true) {
        formData.eingereicht = true
        let proj: any = {
          formData,
          formName,
          awardType, 
          year,
          id: this.id,
          part: this.currentPart,
        }
  
        this.$store.dispatch('saveProject', proj).then((project) => {
          console.log('saveProject -> project', project)
          this.isLoading = false
          this.$router.push('/anmelden/index')
        }).catch((err: Error) => {
          console.log('saveProject -> err', err)
          this.isLoading = false
          this.$store.commit('setAlert', {
            type: 'error',
            message: 'Fehler beim übermitteln! Bitte prüfen Sie die Eingaben.'
          })
        })
      }
      return false
    },

    isNotNested,
    parseData, 
    parseForm,
  },

  mounted () {
    this.initProject();
  },

  destroyed () {
    if (this.interv) {
      clearInterval(this.interv)
    }
  },

});
